.adcm-visibility {
  height: 4000px;
  position: relative;

  &_sticky {
    position: sticky;
    top: 10px; /* Adjust the top position as needed */
    left: 10px; /* Adjust the position for better visibility */
    z-index: 100; /* Ensures it stays above other elements */
    background-color: white; /* Optional: to ensure it’s readable */
    padding: 5px;
    width: max-content; /* Prevents it from stretching horizontally */
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
}

.adcm-visibility__title {
  text-align: center;
}

.adcm-visibility__filler {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.adcm-visibility__banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
