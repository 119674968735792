html {
  position: relative;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.App__title {
  text-align: center;
}

.App__content {
  margin: 20px;
}
